import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MedicamentosService } from '../../services/medicamentos.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-medicamentos',
  templateUrl: './medicamentos.component.html',
  styles: []
})
export class MedicamentosComponent implements OnInit, OnDestroy {
  loading = false;

  medicamentos: any[] = [];

  // Todo lo del paginador

  newPage: number;
  currentPage = 1;

  // Paginador ends

  subscripcion;

  productSortOrder;

  showFiltros = false;
  term;

  public labels: any = {
    previousLabel: '',
    nextLabel: '',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };

  constructor(
    private _medicamentosService: MedicamentosService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.loading = true;
    /*
    this.subscripcion = this._medicamentosService
      .getProductos(4)
      .subscribe(resp => {
        this.medicamentos = resp;
        this.loading = false;
      });*/
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(filter(params => params.page))
      .subscribe(params => {
        this.currentPage = params.page;
      });
  }

  ngOnDestroy(): void {
    this.subscripcion.unsubscribe();
  }

  pageChange(newPage: number) {
    this.router.navigate(['/medicamentos'], { queryParams: { page: newPage } });
  }

  sortD() {
    this.currentPage = 1;
    this.medicamentos.sort(
      (a, b) => parseFloat(a.precio) - parseFloat(b.precio)
    );
  }

  sortA() {
    this.currentPage = 1;
    this.medicamentos.sort(
      (a, b) => parseFloat(b.precio) - parseFloat(a.precio)
    );
  }

  sortAlpha() {
    this.medicamentos.sort(function (a, b) {
      if (a.nombre < b.nombre) {
        return -1;
      }
      if (a.nombre > b.nombre) {
        return 1;
      }
      return 0;
    });

    this.currentPage = 1;
  }
}
