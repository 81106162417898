import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent implements OnInit {

  year: number;
  proveedores: boolean;
  privacidad: boolean;

  constructor( public dialog: MatDialog ) {

    this.year = new Date().getFullYear();

   }

  ngOnInit() {

  }

  openPrivacidad() {
    const dialogRef = this.dialog.open(AvisoPrivacidad, {
      height: '500px',
      backdropClass:'modalBack'
    });
  }

  // openProveedores() {
  //   const dialogRef = this.dialog.open(AvisoProveedores, {
  //     height: '500px',
  //     backdropClass:'modalBack'
  //   });
  // }

}


@Component({
  selector: 'app-aviso-privacidad',
  templateUrl: 'aviso-privacidad.html',
})
// tslint:disable-next-line:component-class-suffix
export class AvisoPrivacidad {}

@Component({
  selector: 'app-aviso-proveedores',
  templateUrl: 'aviso-proveedores.html',
})
// tslint:disable-next-line:component-class-suffix
export class AvisoProveedores {}
