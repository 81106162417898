import { Component, OnInit } from '@angular/core';
import { BuscadorService } from '../../services/buscador.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-buscando',
  templateUrl: './buscando.component.html',
  styles: [],
})
export class BuscandoComponent implements OnInit {
  productos = [];
  page = 1;

  constructor(
    private buscadorService: BuscadorService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (!params.busqueda) {
        return;
      } else {
        this.buscadorService.getProductos(params.busqueda).subscribe(resp => {
          console.log("buscando")
          console.log(resp)
          this.productos = resp;
        });
      }
    });
  }
}
