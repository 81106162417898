import { Component, OnInit } from '@angular/core';
import { PromocionesService } from '../../../services/promociones.service';

@Component({
  selector: 'app-simitel',
  templateUrl: './simitel.component.html',
  styles: []
})
export class SimitelComponent implements OnInit {

  simitelefonos = [
    {
    'numero': 0,
    'nombre': 'Operadora'
   },
   {
     'numero': 1,
     'nombre': 'Ventas'
   },
   {
     'numero': 2,
     'nombre': 'Sistemas'
   },
   {
      'numero': 3,
      'nombre': 'Franquicias'
   },
   {
     'numero': 4, 'nombre': 'Compras',
   },
   {
     'numero': 5,
     'nombre': 'RRHH'
   },
    {
      'numero': 6,
      'nombre': 'Cuentas por pagar'
    },
    {
      'numero': 7,
      'nombre': 'Distribución'
    },
    {
      'numero': 8,
      'nombre': 'Contabilidad'
    }
];

  constructor( ) { }

  ngOnInit() {
  }

}
