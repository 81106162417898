import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { APP_ROUTES } from './app.routes';

import { PagesModule } from './pages/pages.module';

// Servicios
import { InfosimiService } from './services/infosimi.service';
import { SpotifyService } from './services/spotify.service';
import { LocalizaService } from './services/localiza.service';
import { ContactoService } from './services/contacto.service';
import { BannerService } from './services/banner.service';
import { PromocionesService } from './services/promociones.service';
import { BuscadorService } from './services/buscador.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LoginGuardGuard } from './services/guards/login-guard.guard';
import { LoginComponent } from './login/login.component';
import { LoadingModule } from 'ngx-loading';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { ErrorComponent } from './error/error.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core';

import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { ContactosComponent } from './contactos/contactos.component';

library.add(faCoffee);
// the second parameter 'fr' is optional
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ErrorComponent,
    ContactosComponent
  ],
  imports: [
    PagesModule,
    FontAwesomeModule,
    NgbDropdownModule.forRoot(),
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatDialogModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    APP_ROUTES,
    LoadingModule.forRoot({
      backdropBackgroundColour: 'rgba(0,0,0,0.0)',
      backdropBorderRadius: '4px',
      primaryColour: '#1e88e5',
      secondaryColour: '#1e88e5',
      tertiaryColour: '#1e88e5'
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    LoginGuardGuard,
    InfosimiService,
    SpotifyService,
    BuscadorService,
    LocalizaService,
    ContactoService,
    BannerService,
    PromocionesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
