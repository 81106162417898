import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bolsa-de-trabajo',
  templateUrl: './bolsa-de-trabajo.component.html',
  styles: []
})
export class BolsaDeTrabajoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
