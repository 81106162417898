import { Component, OnInit } from '@angular/core';
import { PromocionesService } from '../../services/promociones.service';

@Component({
  selector: 'app-bases-legales',
  templateUrl: './bases-legales.component.html',
  styleUrls: ['./bases-legales.component.css']
})
export class BasesLegalesComponent implements OnInit {
  promociones: any;

  constructor(
    public _promocionesService: PromocionesService
  ) {
    this._promocionesService.getLegales().subscribe((resp: any) => {
      this.promociones = resp;
    });
  }

  ngOnInit() {
  }



}
