import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'votacion'
})
export class VotacionPipe implements PipeTransform {

  transform(votaciones: any[]): any {

    // tslint:disable-next-line:prefer-const
    let likes: any[] = [];

    function checkTrue(votes) {
      return votes.meGusta === true;
    }

    if ( votaciones.length <= 0 ) {
      return 0;
    }

  if ( votaciones.length >= 1 ) {

     likes =  votaciones.filter(checkTrue);
     return (likes).length;
  }



  }

}
