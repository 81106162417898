import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoadingModule } from 'ngx-loading';
import { HeaderComponent } from './header/header.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import {
  FooterComponent,
  AvisoPrivacidad,
  AvisoProveedores
} from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SinimgPipe } from '../pipes/sinimg.pipe';
import { UrlPipe } from '../pipes/url.pipe';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { SidebarPromocionesComponent } from './sidebar-promociones/sidebar-promociones.component';
import { ListItemComponent } from './list-item/list-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBook,
  faCheck,
  faBriefcase,
  faEnvelope,
  faPhone,
  faBuilding,
  faSearch,
  faBullseye,
  faCrosshairs,
  faEye,


} from '@fortawesome/free-solid-svg-icons';

import { SidebarNuevosProductosComponent } from './sidebar-nuevos-productos/sidebar-nuevos-productos.component';
import { MatIconModule } from '@angular/material/icon';
import { BioequivalentePipe } from '../pipes/bioequivalente.pipe';

library.add(
  faBook,
  faCheck,
  faBriefcase,
  faEnvelope,
  faPhone,
  faBuilding,
  faSearch,
  faBullseye,
  faCrosshairs,
  faEye
);

@NgModule({
    imports: [
        MatIconModule,
        NgbModule,
        RouterModule,
        FormsModule,
        CommonModule,
        FontAwesomeModule,
        NgxPaginationModule,
        LoadingModule
    ],
    declarations: [
        BioequivalentePipe,
        SinimgPipe,
        UrlPipe,
        HeaderComponent,
        FooterComponent,
        NavbarComponent,
        BreadcrumbsComponent,
        NopagefoundComponent,
        SidebarPromocionesComponent,
        SidebarNuevosProductosComponent,
        ListItemComponent,
        AvisoPrivacidad,
        AvisoProveedores
    ],
    exports: [
        BioequivalentePipe,
        SinimgPipe,
        UrlPipe,
        HeaderComponent,
        FooterComponent,
        NavbarComponent,
        SidebarPromocionesComponent,
        SidebarNuevosProductosComponent,
        BreadcrumbsComponent,
        NopagefoundComponent,
        AvisoPrivacidad,
        AvisoProveedores
    ]
})
export class SharedModules {}
