import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { InfosimiService, Nota } from '../../services/infosimi.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LOCALE_ID } from '@angular/core';
declare var swal: any;

@Component({
  selector: 'app-infosimi',
  templateUrl: './infosimi.component.html',
  styles: []
})
export class InfosimiComponent implements OnInit, OnDestroy {

  @Input() id: string;

  locale = LOCALE_ID;
  panelOpenState = false;
  page = 1;
  notas: Nota [] = [];

  nota: any = { };
  notes;

  note: any = {};

  k = 2;
  show = false;

  comentarioForm = false;
  notaActual;
  notaActualId;
  votaciones;
  comentarios: any[] = [];
  comentariosSubscription;

  likes = 0;

  constructor( public _infosimiService: InfosimiService,
               private router: Router,
               private activatedRoute: ActivatedRoute ) {


      this.activatedRoute.params.subscribe( params => {
        this.note = this._infosimiService.getNote( params['id'] ).subscribe( resp => {
          this.note = resp;
        });
        this.notaActual = params.url;
        this.notaActualId = params.id;


      this.comentariosSubscription = this._infosimiService.getComentarios( this.notaActual ).subscribe( (resp: any) => {
        this.comentarios = resp;
        });
      });


   }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
    this._infosimiService.getNotes().subscribe( resp => {
      this.notas = resp;
    });
  }

  ngOnDestroy() {
    this.comentariosSubscription.unsubscribe();
  }

  verNota( idx: number, url: string ) {
    this.router.navigate(['/infosimi', idx, url]);
 }

 siguienteNota( sidx: number, url: string ) {
   // tslint:disable-next-line:prefer-const
   let sig: number = sidx + 1;
   if ( sig < this.notas.length ) {
    this.router.navigate(['/infosimi', sig, url]);
  } else {
    this.router.navigate(['/infosimi', 0, url]);
   }
 }

 anteriorNota( aidx: number, url: string ) {
  const ant: number = aidx - 1;
  if ( ant < aidx && aidx > 0) {
    this.router.navigate(['/infosimi', ant, url]);
  } else {
    this.router.navigate(['/infosimi', this.notas.length - 1, url]);
   }
 }

 sendComment(form: NgForm) {

    const comentario = {
        'moderador': false,
        'comentarioPadreId': null,
        'comentarios': form.value.comentario,
        'urlNota': this.notaActual,
        'aprobado': false,
        'interesado': {
          'nombre': form.value.nombre,
          'correoElectronico': form.value.correo
          }
        };

      this._infosimiService.insertComment( comentario ).subscribe( resp => {
        window.scrollTo(0, 0);
        form.reset();
        swal('Gracias por tus comentarios', 'Tu comentario sera evaluado en breve', 'success');
      }, err => {
        swal('No se pudo enviar el comentario', err.error, 'error');
        console.log(err.error);
      });
      this.comentarioForm = false;
 }



insertReply( form: NgForm ) {

  const respuesta = {
    'moderador': false,
    'comentarioPadreId': form.value.folio,
    'comentarios': form.value.comentario,
    'urlNota': this.notaActual,
    'aprobado': false,
    'interesado': {
      'nombre': form.value.nombre,
      'correoElectronico': form.value.correo
      }
    };

    this._infosimiService.insertComment( respuesta ).subscribe( resp => {
      console.log(resp);
      window.scrollTo(0, 0);
      form.reset();
      swal('Tu respuesta ha sido enviada', 'Gracias', 'success');
    });
}


vote( comentarioId: number, vote: boolean ) {
  console.log(comentarioId, vote);
  this._infosimiService.votacion( comentarioId, vote );
  window.location.reload();
}


collapseID( id: number ) {

 const element =  document.getElementById(`${id}`);

 if ( element.classList.contains('show') ) {
   element.classList.remove('show');
  } else {
    element.classList.add('show');
 }


}

}

