
import {filter} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MedicamentosService } from '../../services/medicamentos.service';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-higiene-perfumeria',
  templateUrl: './higiene-perfumeria.component.html'
})
export class HigienePerfumeriaComponent implements OnInit, OnDestroy {

  loading = false;

  newPage: number;
  currentPage = 1;

  higienes: any[] = [];

  subscripcion;


  constructor( public _medicamentosService: MedicamentosService,
                      private router: Router,
                      private activatedRoute: ActivatedRoute ) {
    this.loading = true;

   /* this.subscripcion = this._medicamentosService.getProductos(1).subscribe( resp => {
      this.higienes = resp;
      this.loading = false;
    });*/
   }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(
    filter(params => params.page))
    .subscribe(params => {
      this.currentPage = params.page;
    });
  }

  ngOnDestroy(): void {
  this.subscripcion.unsubscribe();
  }

  pageChange(newPage: number) {
    // console.log(newPage);
    this.router.navigate(['/higiene-y-perfumeria'], {queryParams: { page: newPage }});
  }

  sortD() {
    this.currentPage = 1;
    this.higienes.sort((a, b) => parseFloat(a.precio) - parseFloat(b.precio));
  }

  sortA() {
    this.currentPage = 1;
    this.higienes.sort((a, b) => parseFloat(b.precio) - parseFloat(a.precio));
  }

sortAlpha() {
  this.higienes.sort(function(a, b) {
    if (a.nombre < b.nombre) {
      return -1;
    }
    if (a.nombre > b.nombre) {
      return 1;
    }
    return 0;
});

this.currentPage = 1;

}

}
