export class Contacto {
  constructor(
    public asuntoId: number,
    public nombre: string,
    public correoElectronico: string,
    public telefono: string,
    public comunaId: number,
    public mensaje: string
  ) {}
}

// "asuntoId": 0,
//   "nombre": "string",
//   "correoElectronico": "string",
//   "telefono": "string",
//   "comunaId": 0,
//   "mensaje": "string"
