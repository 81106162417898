import { forkJoin as observableForkJoin, Observable, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from '../config/config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedicamentosService {
 /* categoria: any[] = [];
  productos: any[] = [];
  nuevos: any[] = [];
  urls: any[] = [];
  calificaciones: any[] = [];

  constructor(public http: HttpClient) { }

  getProductos(id: number) {
    this.productos = [];
    const url = `${URL_SERVICIOS}/Productos/${id}`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        resp.forEach(element => {
          if (
            element.nombre != null &&
            element.nombre.length > 2 &&
            element.nombre !== 'MANCHON DOCTOR SIMI' &&
            element.nombre !== 'AGUA OXIGENADA CREMA' &&
            element.fechaInclusion !== '1900-01-01T00:00:00'
          ) {
            this.productos.push(element);
          }
        });
        return this.productos;
      })
    );
  }

  // este de la página de error
  getCategoria(id: number) {
    // tslint:disable-next-line:no-shadowed-variable
    const url = `${URL_SERVICIOS}/Productos/${id}`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        resp.forEach(element => {
          if (
            element.nombre != null &&
            element.nombre.length > 2 &&
            element.nombre !== 'MANCHON DOCTOR SIMI' &&
            element.nombre !== 'AGUA OXIGENADA CREMA' &&
            element.fechaInclusion !== '1900-01-01T00:00:00'
          ) {
            this.categoria.push(element);
          }
        });
        return this.categoria;
      })
    );
  }

  getCalificacion(id: number) {
    this.calificaciones = [];
    const url = `${URL_SERVICIOS}/Productos/Votacion/${id}`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        resp.forEach(element => {
          this.calificaciones.push(element.estrellas);
        });
        return this.calificaciones;
      })
    );
  }

  votacion(id: number, voto: number, comentario: string) {
    // tslint:disable-next-line:prefer-const
    let vote = {
      productoId: id,
      estrellas: voto,
      comentario: comentario
    };

    const url = `${URL_SERVICIOS}/Productos/Votacion`;

    return this.http.post(url, vote).pipe(
      map((resp: any) => {
        return console.log(resp);
      })
    );
  }

  getVarios() {
    const url = `${URL_SERVICIOS}/Productos/`;
    return forkJoin([this.http.get(`${url}7`), this.http.get(`${url}3`)]).pipe(
      map(resps => {
        const array = [];
        resps.forEach((element: any) => {
          element.forEach(object => {
            if (
              object.nombre != null &&
              object.nombre.length > 2 &&
              object.nombre !== 'MANCHON DOCTOR SIMI' &&
              object.nombre !== 'AGUA OXIGENADA CREMA' &&
              object.fechaInclusion !== '1900-01-01T00:00:00'
            ) {
              array.push(object);
            }
          });
        });
        return array;
      })
    );
  }

  getNuevosProductos(): Observable<any> {
    return observableForkJoin(
      this.http.get(`${URL_SERVICIOS}/Productos/1`),
      this.http.get(`${URL_SERVICIOS}/Productos/2`),
      this.http.get(`${URL_SERVICIOS}/Productos/3`),
      this.http.get(`${URL_SERVICIOS}/Productos/4`),
      this.http.get(`${URL_SERVICIOS}/Productos/5`),
      this.http.get(`${URL_SERVICIOS}/Productos/6`)
    );
  }*/
}
