import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sinimg'
})
export class SinimgPipe implements PipeTransform {

  transform(imagenes: any): any {


    const sinimg = 'assets/images/placeholder.jpg';

    if ( !imagenes ) {
      return sinimg;
    }


    return ( imagenes.length > 0 ) ? imagenes[1].url : sinimg;
  }

}
