import { Component, OnInit, OnDestroy } from '@angular/core';
import { MedicamentosService } from '../../services/medicamentos.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'app-sidebar-nuevos-productos',
  templateUrl: './sidebar-nuevos-productos.component.html',
  styleUrls: ['./sidebar-nuevos-productos.component.css'],
})
export class SidebarNuevosProductosComponent implements OnInit, OnDestroy {
  fechaActual = new Date();
  nuevos: any[] = [];
  public maxSize = 6;
  public directionLinks = true;
  public autoHide = false;
  public config: PaginationInstance = {
    id: 'nuevos',
    itemsPerPage: 3,
    currentPage: 1,
  };

  public labels: any = {
    previousLabel: '',
    nextLabel: '',
    screenReaderPaginationLabel: 'Paginación',
    screenReaderPageLabel: 'Pagina',
    screenReaderCurrentLabel: `Actual`,
  };

  loading: Boolean;
  subscripcion;

  // 15778476000 milisegundos = 6 meses

  constructor(public _medicamentosService: MedicamentosService) {
    if (sessionStorage.getItem('nuevos')) {
      this.nuevos = JSON.parse(sessionStorage.getItem('nuevos'));
    } else {
      this.loading = true;
     /* this.subscripcion = this._medicamentosService
        .getNuevosProductos()
        .subscribe(resp => {
          resp.forEach(arreglos => {
            arreglos.forEach(element => {
              if (element.nuevo && element.nombre !== '.') {
                this.nuevos.push(element);
              }
            });
          });
          sessionStorage.setItem('nuevos', JSON.stringify(this.nuevos));
          this.loading = false;
        });*/
    }
  }

  ngOnInit() { }

  ngOnDestroy() {
    if (this.subscripcion) {
      this.subscripcion.unsubscribe();
    }
  }

  onPageChange(number: number) {
    this.config.currentPage = number;
  }
}
