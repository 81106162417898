import { Component, OnInit } from '@angular/core';
import { fadeInAnimation } from '../../_animations/index';
import { BuscadorService } from '../../services/buscador.service';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  animations: [fadeInAnimation],
  // tslint:disable-next-line:use-host-property-decorator
  host: { '[@fadeInAnimation]': '' }
})
export class BusquedaComponent implements OnInit {
  page = 1;
  busqueda: string;
  public labels: any = {
    previousLabel: '',
    nextLabel: '',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };

  constructor(public _buscadorService: BuscadorService) { }

  ngOnInit() {

  }

  sortD() {
    this.page = 1;
    this._buscadorService.productos.sort(
      (a, b) => parseFloat(a.precio) - parseFloat(b.precio)
    );
  }

  sortA() {
    this.page = 1;
    this._buscadorService.productos.sort(
      (a, b) => parseFloat(b.precio) - parseFloat(a.precio)
    );
  }

  sortAlpha() {
    this._buscadorService.productos.sort(function (a, b) {
      if (a.nombre < b.nombre) {
        return -1;
      }
      if (a.nombre > b.nombre) {
        return 1;
      }
      return 0;
    });

    this.page = 1;
  }
}
