import { map } from 'rxjs/operators';
import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { SpotifyService } from '../../services/spotify.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { BuscadorService } from '../../services/buscador.service';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { MedicamentosService } from '../../services/medicamentos.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { slideInOutAnimation, fadeInAnimation } from '../../_animations';
declare var swal: any;

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  providers: [NgbRatingConfig],
  styles: [
    `
      .star {
        font-size: 1.5rem;
        color: #b0c4de;
      }
      .filled {
        color: #ffc107;
      }
      .bad {
        color: #ffc107;
      }
      .filled.bad {
        color: #ffc107;
      }
      .dark-modal .modal-content {
        background-color: #292b2c;
        color: white;
      }
    `,
  ],
  animations: [slideInOutAnimation],
})
export class ProductoComponent implements OnInit, OnDestroy {
  producto: any = {};
  visible = false;
  showVotacion = false;
  animation = '';
  currentRate = 3;
  calificaciones = [];
  subscripcion;
  totalCalificacion = 0;
  votacion = 0;
  calificacion = 0;
  comentario = '';
  elModal;
  firstImage = true;
  secondImages = false;
  noImages = false;
  imgUrl = '';
  isBioequivalente = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public _spotify: SpotifyService,
    public _buscadorService: BuscadorService,
    public _medicamentoService: MedicamentosService,
    public title: Title,
    public meta: Meta,
    public router: Router,
    private modalService: NgbModal,
    config: NgbRatingConfig
  ) {
    config.max = 5;

    this.activatedRoute.params
      .pipe(map(params => params['id']))
      .subscribe(id => {
        this.subscripcion = this._buscadorService
          .getProducto(id)
          .subscribe((producto: any) => {
            if (producto.nombre.includes('(BE)')) {
              producto.nombre = producto.nombre.substr(
                0,
                producto.nombre.length - 4
              );
              this.isBioequivalente = true;
            } else if (producto.clave.includes('BE')) {
              this.isBioequivalente = true;
            } else {
              this.isBioequivalente = false;
            }

            this.producto = producto;
            this.firstImage = true;
            this.secondImages = false;
            this.noImages = false;

            /*// Obtenemos el voto
            this._medicamentoService
              .getCalificacion(this.producto.productoId)
              .subscribe(resp => {
                this.calificaciones = resp;
                if (resp.length >= 1) {
                  this.totalCalificacion = this.calificaciones.reduce(
                    this.getSum
                  );
                  this.votacion = Math.ceil(
                    this.totalCalificacion / resp.length
                  );
                } else {
                  this.votacion = 0;
                }
              });*/

            // Actualizamos el Titulo de la pagina
            this.title.setTitle(
              `${this.producto.nombre} | Farmacias del Dr. Simi`
            );
            const metaTag: MetaDefinition = {
              name: 'description',
              //content: `${producto.nombre}, Precio: ${producto.precio}, Presentación: ${producto.presentacion}`,
              content: `${producto.nombre}, Presentación: ${producto.presentacion}`,
            };
            this.meta.updateTag(metaTag);
          });
      });
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscripcion.unsubscribe();
  }

  getSum(total: number, number: number) {
    return total + number;
  }

  openVotoModal(content) {
    this.showVotacion = false;
    this.comentario = '';
    this.elModal = this.modalService.open(content, {
      centered: true,
      windowClass: 'dark-modal',
    });
  }

  openModal() {
    this.visible = true;
    this.animation = 'enter';
    const modal = document.getElementById('detalleModal');
    modal.classList.add('show');
    modal.style.display = 'block';
    modal.style.overflow = 'visible';
    modal.style.overflowX = 'hidden';
  }

  closeModal() {
    this.animation = 'leave';
    this.visible = false;
    const modal = document.getElementById('detalleModal');
    modal.classList.remove('show');
    modal.style.display = 'none';
  }

  outFocus() {
    this.closeModal();
  }

  checkLength(length) { }

 /* votar() {
    if (this.comentario.length >= 5 && this.calificacion >= 1) {
      this._medicamentoService
        .votacion(this.producto.productoId, this.calificacion, this.comentario)
        .subscribe(
          resp => {
            this.comentario = '';
            this.elModal.close();
            swal('Tu votación ha sido enviada');
          },
          err => {
            swal('Oops', err.message.slice(95, 110), 'error');
          }
        );
    }
  }*/

  nextImages() {
    this.firstImage = false;
    this.secondImages = true;
  }

  noImage() {
    this.firstImage = false;
    this.secondImages = false;
    this.noImages = true;
  }
}
