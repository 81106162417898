import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { InfosimiService } from '../services/infosimi.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html'
})
export class PagesComponent implements OnInit, OnDestroy {


  window: Element;


  openSidenav = false;

  length;
  latestIdx;
  latestUrl;

  subscripcion: Subscription;

  constructor( public router: Router,
               public _infosimiService: InfosimiService) {


        this.router.events.subscribe( event => {
          if ( event instanceof NavigationEnd ) {
            (<any>window).ga('set', 'page', event.urlAfterRedirects);
            (<any>window).ga('send', 'pageview');
          }
        });


        _infosimiService.getNotes().subscribe( resp => {
          this.length = resp.length;
          this.latestIdx = (this.length - 1);
          this.latestUrl = resp[this.latestIdx].url;
          // console.log( this.latestUrl );
        });

      }

  ngOnInit() {
    this.subscripcion = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          const contentContainer = document.querySelector('.mat-sidenav-content') || this.window;
          contentContainer.scrollTo(0, 0);
      }
  });

   }

   ngOnDestroy() {
     this.subscripcion.unsubscribe();
   }



}
