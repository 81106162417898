import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import { SpotifyService } from '../../services/spotify.service';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { InfosimiService } from '../../services/infosimi.service';

import { BuscadorService } from '../../services/buscador.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  @Input() termino = '';
  @Output() cambioTermino: EventEmitter<string> = new EventEmitter();
  @ViewChildren('lista') div: QueryList<ElementRef>;

  number = 0;
  length = 0;
  latestIdx = 0;
  latestUrl = '';
  productos = [];
  public maxSize = 6;
  public directionLinks = true;
  public autoHide = false;
  public configuracion: PaginationInstance = {
    id: 'header',
    itemsPerPage: 5,
    currentPage: 1
  };

  public labels: any = {
    previousLabel: '',
    nextLabel: '',
    screenReaderPaginationLabel: 'Paginación',
    screenReaderPageLabel: 'Pagina',
    screenReaderCurrentLabel: `Actual`
  };

  constructor(
    public _spotify: SpotifyService,
    public _infosimiService: InfosimiService,
    public _buscadorService: BuscadorService,
    public elem: ElementRef,
    public router: Router
  ) {
    // console.log( _infosimiService.getNotas());
    _infosimiService.getNotes().subscribe(resp => {
      this.length = resp.length;
      this.latestIdx = this.length - 1;
      this.latestUrl = resp[this.latestIdx].url;
      // console.log( this.latestUrl );
    });
    // console.log(this.latestUrl);
  }

  onPageChange(number: number) {
    this.configuracion.currentPage = number;
  }

  buscarProducto() {
    this.cambioTermino.emit(this.termino);
    if (this.termino.length < 3) {
      return;
    }

    this._buscadorService.getProductos(this.termino).subscribe((resp: any) => {
      console.log(this.productos)
      this.productos = resp;
      this.productos.forEach((item, index) => {
        if (item['$id'] === '1') this.productos.splice(index, 1);
      });

    });
  }

  redirects(){

    //window.open('https://www.drsimi.cl/', '_blank', 'noopener');
  }

  buscarArtistas() {
    this.cambioTermino.emit(this.termino);
    if (this.termino.length === 0) {
      return;
    }
    this._spotify.getArtistas(this.termino).subscribe(artistas => { });
  }

  buscarTermino() {
    if (this._buscadorService.productos.length >= 1) {
      this.router.navigate(['/busqueda']);
      this.termino = '';
    } else {
      return;
    }
  }

  navigation(event) {
    // tslint:disable-next-line:prefer-const
    let primero = event.target.nextElementSibling.childNodes[6];
    primero.focus();
    // console.log(event.target.nextElementSibling.childNodes[6]);
  }

  ngOnInit() {
    // Scroll HEder0o
    // let scrollpos = window.scrollY;
    // const vacio = document.getElementById('vacio');
    // const header = document.getElementById('navBar');
    // function add_class_on_scroll() {
    //     header.classList.add('fixed-top');
    //     header.classList.add('matNav');
    //     vacio.classList.add('display-block');
    // }
    // function remove_class_on_scroll() {
    //     header.classList.remove('fixed-top');
    //     header.classList.remove('matNav');
    //     vacio.classList.remove('display-block');
    // }
    // window.addEventListener('scroll', function() {
    //     scrollpos = window.scrollY;
    //     if (scrollpos > 83) {
    //         add_class_on_scroll();
    //     } else {
    //         remove_class_on_scroll();
    //     }
    //     console.log(scrollpos);
    // });
  }

  collapseFalse() {
    const navbarCollapse = document.getElementById('navbarSupportedContent1');
    navbarCollapse.classList.remove('show');
  }
}
