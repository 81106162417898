import { Component, OnInit } from '@angular/core';
import { PromocionesService } from '../../services/promociones.service';
import { fadeInAnimation } from '../../_animations/fade-in.animation';
import { slideInOutAnimation } from '../../_animations';
import { InfosimiService, Nota } from '../../services/infosimi.service';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  animations: [slideInOutAnimation, fadeInAnimation],
})
export class PromocionesComponent implements OnInit {
  promociones: any[] = [];
  notas: Nota[] = [];
  page = 1;
  k = 2;
  entrance = 'fadeIn';
  visible = false;
  animation = '';

  constructor(public _promocionesService: PromocionesService, public _infosimiService: InfosimiService) {
    this._promocionesService.getPromociones().subscribe((resp: any) => {
      this.promociones = resp;
    });
    this._infosimiService.getNotes().subscribe(resp => {
      this.notas = resp;
    });
  }



  ngOnInit() { }

  closeModal() {
    this.visible = false;
    this.animation = 'leave';
    const modal = document.getElementById('promocionModal');
    modal.classList.remove('show');
    modal.style.display = 'none';
  }

  getPromocion(key: number) {
    this.visible = true;
    this.animation = 'enter';
    const modal = document.getElementById('promocionModal');
    modal.classList.add('show');
    modal.style.display = 'block';
    modal.style.overflow = 'visible';
    modal.style.overflowX = 'hidden';
    this.k = key;
  }
}
