import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BannerService {
  // public banners: any[] =
  banners: any[];

  constructor(public http: HttpClient) { }

  getBanners() {
    const url = './assets/data/bannerinterior.json';
    return this.http.get(url);
  }

  getBannersHome() {
    const url = './assets/data/bannerhome.json';
    return this.http.get(url);
  }
}
