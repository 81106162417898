import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nota',
  templateUrl: './nota.component.html',
  styles: []
})
export class NotaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
