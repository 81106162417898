
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';



@Injectable()
export class SpotifyService {

  artistas: any[] = [];
  urlSpotify = 'https://api.spotify.com/v1/';
  token = 'BQBwow-iNBZmG9bDGupZWsCfpPqHV4vw7xjSvngpUjK28NIiXTkd-pTXW21wCvSpzwlTuEEFctfVhd77Fro';

  constructor( public http: HttpClient ) {}

   private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'authorization': 'Bearer ' + this.token
    });
    return headers;
   }


  getArtista( id: string ) {
    const url = `${ this.urlSpotify }artists/${ id }`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers });
  }


   getArtistas( termino: string ) {
     const url = `${ this.urlSpotify }search?query=${termino}&type=artist&limit=20`;
     const headers = this.getHeaders();
     return this.http.get(url, { headers }).pipe(
                map( (resp: any) => {
                  this.artistas = resp.artists.items;
                  // console.log(this.artistas);
                  return this.artistas;
                }));
  }

}
