import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PromocionesService {
  promociones: any[];

  constructor(public http: HttpClient) { }

  getPromociones() {
    const url =
      './assets/data/promociones.json';
    return this.http.get(url);
  }

  getPromocion(idx: number) {
    const url =
      './assets/data/promociones.json';
    return this.promociones[idx];
  }

  getLegales() {
    const url = 'assets/data/legales.json';
    return this.http.get(url);
  }
}
