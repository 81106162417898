
import { map, filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';


import { Title, Meta } from '@angular/platform-browser';
import { BannerService } from '../../services/banner.service';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent implements OnInit {

  // tslint:disable-next-line:no-inferrable-types
  label: string = '';

  banners: any[] = [];
  banner: any = {};

  numero = 1;
  newNumero: number;
  link: string;
  idx = '1';
  constructor(private router: Router,
    public title: Title,
    public meta: Meta,
    public _bannerService: BannerService) {


    this.getDataRoute().subscribe(data => {

      // console.log(data);

      this.label = data.titulo;
      this.title.setTitle(this.label + ' | ' + 'Farmacias del Dr. Simi');


    });

  }

  getDataRoute() {
    return this.router.events.pipe(
      filter(evento => evento instanceof ActivationEnd),
      filter((evento: ActivationEnd) => evento.snapshot.firstChild === null),
      map((evento: ActivationEnd) => evento.snapshot.data));
  }

  ngOnInit() {


    this._bannerService.getBanners().subscribe((resp: any) => {
      this.banners = resp;
      this.newNumero = Math.floor(Math.random() * this.banners.length);
      this.banner = this.banners[this.newNumero]
    });

  }


}
