import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from '../config/config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BuscadorService {
  productos: any[] = [];
  URL_SERVICIOS = URL_SERVICIOS;

  constructor(public http: HttpClient) {}

  getProductos(termino: string) {
    const url = `${URL_SERVICIOS}/Productos/Like/${termino}`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        return (this.productos = resp);
      })
    );
  }

  getProducto(id: string) {
    const url = `${URL_SERVICIOS}/Productos/Detalles/${id}`;
    return this.http.get(url);
  }
}
