import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MedicamentosService } from '../../services/medicamentos.service';
import {
  Router,
  ActivatedRoute
} from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-suplementos',
  templateUrl: './suplementos.component.html',
  styles: []
})
export class SuplementosComponent implements OnInit, OnDestroy {
  loading = false;

  suplementos: any[] = [];

  subscripcion;

  currentPage = 1;

  firstImg = true;
  nextImg = false;
  default = false;

  src;

  constructor(
    public _medicamentosService: MedicamentosService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.loading = true;
    /*
    this.subscripcion = this._medicamentosService
      .getProductos(6)
      .subscribe(resp => {
        this.suplementos = resp;
        this.loading = false;
      });*/
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(filter(params => params.page))
      .subscribe(params => {
        this.currentPage = params.page;
      });
  }

  ngOnDestroy() {
    this.subscripcion.unsubscribe();
  }

  pageChange(newPage: number) {
    // console.log(newPage);
    this.router.navigate(['/suplementos'], { queryParams: { page: newPage } });
  }

  sortD() {
    this.currentPage = 1;
    this.suplementos.sort(
      (a, b) => parseFloat(a.precio) - parseFloat(b.precio)
    );
  }

  sortA() {
    this.currentPage = 1;
    this.suplementos.sort(
      (a, b) => parseFloat(b.precio) - parseFloat(a.precio)
    );
  }

  sortAlpha() {
    this.suplementos.sort(function(a, b) {
      if (a.nombre < b.nombre) {
        return -1;
      }
      if (a.nombre > b.nombre) {
        return 1;
      }
      return 0;
    });
    this.currentPage = 1;
  }
}
