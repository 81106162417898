import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'url'
})
export class UrlPipe implements PipeTransform {

  transform(value: string ): any {

    // tslint:disable-next-line:prefer-const
    let valor = value.toLowerCase();

    const palabras = valor.split(' ');

    // tslint:disable-next-line:prefer-const
    // tslint:disable-next-line:forin
    for ( const i in palabras ) {

      palabras[i] = palabras[i];

    }



    return palabras.join('-');
  }

}
