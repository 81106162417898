import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modelo-cultural',
  templateUrl: './modelo-cultural.component.html',
  styleUrls: ['./modelo-cultural.component.css']
})
export class ModeloCulturalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
