import { trigger, animate, transition, style } from '@angular/animations';

export const slideInOutAnimation =
trigger('slideInOut', [
    transition(':enter', [
      style({opacity: 0, transform: 'translateY(-30%)'}),
      animate('400ms ease-in-out', style({opacity: 1, transform: 'translateY(0%)'}))
    ]),
    transition(':leave', [
      animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
    ])
  ]);
