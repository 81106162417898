import { Component, OnInit } from '@angular/core';
import { MedicamentosService } from '../services/medicamentos.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  values: any;
  loading = false;
  show = false;
  productos = [];
  productosSinImg = [];

  constructor(public _medicamentosService: MedicamentosService) { }

  ngOnInit() { }

  changeCategoria(id: number) {
    this.loading = true;
    this.productosSinImg = [];
    /*this._medicamentosService.getCategoria(id).subscribe(resp => {
      this.productos = resp;
      console.log(this.productos.length)
      this.loading = false;
    });*/
  }

  mostrar(i: number) {
    const cards = document.querySelectorAll('.card');
    cards[i].classList.remove('display-none');
    console.log(this.productosSinImg);
  }

  newArr(item: any, id: number) {
    item = {
      nombre: item,
      clave: id
    };

    this.productosSinImg.push(item);
    this.loading = false;
    console.log(this.productosSinImg);
  }
}
