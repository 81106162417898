import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MedicamentosService } from '../../services/medicamentos.service';
import {
  Router,
  ActivatedRoute
} from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-varios',
  templateUrl: './varios.component.html'
})
export class VariosComponent implements OnInit, OnDestroy {
  loading = false;
  varios = [];
  currentPage = 1;

  constructor(
    public _medicamentosService: MedicamentosService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(filter(params => params.page))
      .subscribe(params => {
        this.currentPage = params.page;
      });

    this.loading = true;
    /*
    this._medicamentosService.getVarios().subscribe(resp => {

      this.varios = resp;
      this.varios.forEach((item, index) => {
        if (item['clave'] === "CH5937") this.varios.splice(index, 1);
      });
      this.loading = false;
    });*/
  }

  ngOnDestroy() { }

  pageChange(newPage: number) {
    this.router.navigate(['/varios'], { queryParams: { page: newPage } });
  }

  sortD() {
    this.currentPage = 1;
    this.varios.sort((a, b) => parseFloat(a.precio) - parseFloat(b.precio));
  }

  sortA() {
    this.currentPage = 1;
    this.varios.sort((a, b) => parseFloat(b.precio) - parseFloat(a.precio));
  }

  sortAlpha() {
    this.varios.sort(function (a, b) {
      if (a.nombre < b.nombre) {
        return -1;
      }
      if (a.nombre > b.nombre) {
        return 1;
      }
      return 0;
    });
    this.currentPage = 1;
  }
}
