import { Component, OnInit } from '@angular/core';
import { ModalServiceService } from '../app/services/modal-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  isModalOpen = false;

  constructor(private modalService: ModalServiceService){}

  ngOnInit() {
    this.modalService.modalState$.subscribe(isOpen => {
      this.isModalOpen = isOpen;
    });
  }

}


