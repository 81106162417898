import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { InfosimiService } from '../services/infosimi.service';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NgForm } from '@angular/forms';
declare var swal: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: []
})
export class HomeComponent implements OnInit, AfterViewInit {
  comentarios: any[] = [];
  displayedColumns = [
    'folio',
    'nombre',
    'correo',
    'urlNota',
    'comentarios',
    'aprobado',
    'opciones'
  ];
  dataSource = new MatTableDataSource(this.comentarios);

  public loading = false;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  modal = 'modal';

  constructor(
    private _infosimiService: InfosimiService,
    private router: Router
  ) {
    this.loading = true;
    this._infosimiService.moderar().subscribe((resp: any) => {
      this.comentarios = resp;
      this.loading = false;
      console.log(this.comentarios);
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  openModal(id: number) {
    const modal = document.getElementById('demo' + id);
    modal.classList.add('show');
    modal.style.display = 'block';
    modal.style.overflow = 'visible';
    modal.style.overflowX = 'hidden';
  }
  closeModal(id: number) {
    const modal = document.getElementById('demo' + id);
    modal.classList.remove('show');
    modal.style.display = 'none';
  }

  insertReply(form: NgForm) {
    const respuesta = {
      moderador: true,
      comentarioPadreId: form.value.folio,
      comentarios: form.value.comentario,
      urlNota: form.value.urlNota,
      aprobado: true
    };
    console.log(respuesta);
    this._infosimiService.insertComment(respuesta).subscribe(resp => {
      console.log(resp);
      window.scrollTo(0, 0);
      form.reset();
      swal('Hecho', 'Tu respuesta ha sido enviada', 'success');
    });
  }

  borrarComentario(folio: number) {
    swal({
      title: 'Borrando comentario: ' + folio,
      text: 'Para continuar presiona OK',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this._infosimiService.deleteComment(folio);
        swal('El comentario ha sido eliminado', {
          icon: 'error'
        });
      } else {
        swal('No se eliminó');
      }
    });
  }

  aprobarComentario(folio: number) {
    swal({
      title: '¿Aprobar comentario: ' + folio + '?',
      text: 'Para continuar presiona OK',
      icon: 'info',
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this._infosimiService.aprobarComentario(folio).subscribe(
          resp => {
            console.log(resp);
          },
          err => {
            swal('No se pudo enviar el comentario', err.message, 'error');
          }
        );
        swal('El comentario ha sido aprobado', {
          icon: 'success'
        });
      } else {
        swal('No se aprobó');
      }
    });
  }

  logout() {
    this._infosimiService.logout();

    this.router.navigate(['/inicio']);
  }
}
