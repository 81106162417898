import { trigger, animate, transition, style } from '@angular/animations';

export const fadeInAnimation =
trigger('fadeInAnimation', [
    transition('* => fadeIn', [
      style({ opacity: 0 }),
      animate(100, style({ opacity: 1 }))
    ]),
    transition('* => fadeOut', [
      animate(100, style({ opacity: 0 }))
    ])
  ]);
