import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { InfosimiService } from '../services/infosimi.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('someCoolAnimation', [
      transition('* => fadeIn', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ]),
      transition('* => fadeOut', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class LoginComponent implements OnInit {


  bindingVar = '';

  usuario: string;
  password: string;

  constructor( public _infosimiService: InfosimiService,
               public router: Router ) {

                this.bindingVar = 'fadeIn';

               }

  ngOnInit() {
  }

  login( form: NgForm ) {

    this.usuario = form.value.usuario;
    this.password = form.value.password;

    console.log(form.value);

    if ( this.usuario === 'admin' && this.password === 'admin' ) {
      console.log('autenticado!');
      this._infosimiService.guardarStorage( this.usuario, this.password );
      this.fadeOut();
      this.router.navigate(['/admin']);
    } else {
      alert('credenciales incorrectas!');
    }

  }
  fadeIn() {
    this.bindingVar = 'fadeIn';
  }
  fadeOut() {
    this.bindingVar = 'fadeOut';
  }
  toggle() {
    this.bindingVar === 'fadeOut' ? this.fadeIn() : this.fadeOut();
  }



}
