import { Component, OnInit } from '@angular/core';
import { PromocionesService } from '../../services/promociones.service';

@Component({
  selector: 'app-sidebar-promociones',
  templateUrl: './sidebar-promociones.component.html',
  styles: []
})
export class SidebarPromocionesComponent implements OnInit {
  promociones: any[] = [];

  constructor(public _promocionesService: PromocionesService) {
    this._promocionesService.getPromociones().subscribe((resp: any) => {
      this.promociones = resp;
    });
  }

  ngOnInit() {}
}
