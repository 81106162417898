import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Contacto } from '../../models/contacto.model';

import { ContactoService } from '../../services/contacto.service';
import { Router } from '@angular/router';

export interface FormModel {
  captcha?: string;
}

declare var swal: any;

@Component({
  selector: 'app-contactanos',
  templateUrl: './contactanos.component.html',
  styles: []
})
export class ContactanosComponent implements OnInit {
  public formModel: FormModel = {};
  isLoading = false;
  regiones = [];
  provincias: any;
  region: any;
  comunas: any;
  comunaSelect: any;

  constructor(
    public _contactoService: ContactoService,
    public router: Router
  ) {}

  ngOnInit() {
    this._contactoService.getRegiones().subscribe((regiones: any) => {
      const regionesFilter = regiones.filter(region => {
        return region.nombre !== 'PENDIENTE' && region.nombre.length > 1;
      });
      this.regiones = regionesFilter;
    });
  }

  sacarSwal() {
    swal('Gracias por contactarnos', 'Tu opinion es importante', 'success');
  }

  mandarContacto(forma: NgForm) {
    // tslint:disable-next-line:prefer-const
    let contacto = new Contacto(
      forma.value.asunto,
      forma.value.nombre,
      forma.value.mail,
      forma.value.telefono,
      this.comunaSelect,
      forma.value.comentarios
    );

    this.isLoading = true;

    console.log(contacto);

    /*this._contactoService.enviarContacto(contacto).subscribe(
      resp => {
        swal('Gracias por contactarnos', 'Tu opinión es importante', 'success');
        this.router.navigate(['/inicio']);
        console.log(resp);
        this.isLoading = true;
      },
      error => {
        swal('Ocurrío un error', ':(', 'Error');
        console.log(error);
        this.isLoading = false;
      }
    );*/
  }

  getProvincias(regionId: any) {
    this._contactoService.getProvincias().subscribe((provincias: any) => {
      const provinciasFilter = provincias.filter(x => {
        return x.regionId === regionId;
      });
      this.provincias = provinciasFilter;
      console.log(this.provincias);
    });
  }

  getComunas(provinciaId: number) {
    console.log(provinciaId);
    this._contactoService.getComunas().subscribe((comunas: any) => {
      const comunasFilter = comunas.filter((x: { provinciaId: number; }) => {
        return x.provinciaId === provinciaId;
      });
      this.comunas = comunasFilter;
      console.log(this.comunas);
    });
  }

  selectComuna(id: number) {
    this.comunaSelect = id;
  }
}
