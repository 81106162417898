import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { URL_SERVICIOS } from '../config/config';

export interface Nota {
  id: number;
  titulo: string;
  url: string;
  contenido: string;
  intro: string;
  img: string;
}

@Injectable()
export class InfosimiService {
  notes: Nota[];

  usuario: string;
  password: string;

  constructor(public http: HttpClient) {
    this.cargarStorage();
  }

  getLatestCuadernillo(): Observable<any> {
    const url = './assets/data/cuadernillo.json';
    return this.http.get(url);
  }

  estaLogueado() {
    return this.usuario.length > 2 ? true : false;
  }

  cargarStorage() {
    if (localStorage.getItem('usuario')) {
      this.usuario = localStorage.getItem('usuario');
      this.password = localStorage.getItem('contraseña');
    } else {
      this.usuario = '';
      this.password = null;
    }
  }

  guardarStorage(usuario, password) {
    localStorage.setItem('usuario', usuario);
    localStorage.setItem('contraseña', password);
    this.usuario = usuario;
    this.password = password;
  }

  getNotes(): Observable<any> {
    const url = './assets/data/infosimi.json';
    return this.http.get(url);
  }

  getNote(idx: string): Observable<Nota> {
    const url = './assets/data/infosimi.json';
    return this.http.get<Nota[]>(url).pipe(
      map(resp => {
        this.notes = resp;
        //  console.log(this.notes[idx]);
        return this.notes[idx];
      })
    );
  }

  //  getNotas(): Nota[] {
  //    return this.notas;
  //  }

  //  getNota( idx: string ) {
  //    return this.notas[idx];
  //  }

  getComentarios(urlNota) {
    const url = `${URL_SERVICIOS}/Siminotas/${urlNota}`;
    return this.http.get(url);
  }

  insertComment(comentario) {
    const url = `${URL_SERVICIOS}/Siminotas`;
    return this.http.post(url, comentario).pipe(
      map((resp: any) => {
        console.log(resp);
      })
    );
  }

  votacion(folio: number, voto: boolean) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const url = `${URL_SERVICIOS}/Siminotas/Votar/${folio}/${voto}`;
    return this.http.post(url, headers).subscribe(resp => {
      console.log(resp);
    });
  }

  moderar() {
    const url = `${URL_SERVICIOS}/Siminotas/Moderar`;
    return this.http.get(url);
  }

  deleteComment(folio: number) {
    const url = `${URL_SERVICIOS}/Siminotas/${folio}`;
    return this.http.delete(url).subscribe(resp => {
      console.log(resp);
    });
  }

  aprobarComentario(folio: number) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const url = `${URL_SERVICIOS}/Siminotas/Aprobar/${folio}`;
    return this.http.post(url, headers).pipe(
      map(resp => {
        console.log(resp);
      })
    );
  }

  logout() {
    localStorage.removeItem('usuario');
    localStorage.removeItem('contraseña');

    this.usuario = '';
    this.password = null;
  }
}
