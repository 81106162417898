import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  HostListener,
  ElementRef
} from '@angular/core';
import { InfosimiService } from '../../services/infosimi.service';
import { PaginationInstance } from 'ngx-pagination';
import { Router } from '@angular/router';

import { fadeInAnimation } from '../../_animations';
import { AfterViewInit, ViewChild } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { BannerService } from '../../services/banner.service';
import { GeolocationService } from '../../../app/services/geolocation.service';
import { ModalServiceService } from '../../..//app/services/modal-service.service';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  providers: [
    { provide: CarouselConfig, useValue: { interval: 4500, noPause: true, showIndicators: true } }
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./inicio.component.css'],
  animations: [fadeInAnimation]
})
export class InicioComponent implements OnInit, OnDestroy {
  // @ViewChild('letreroBanner', { static: true }) letreroBanner: ElementRef;
  animation = '';
  isMobile: boolean;
  geolocationEnabled = false;

//  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);


  // Todo lo del paginador
  public filter = '';
  public maxSize = 5;
  public directionLinks = true;
  public autoHide = false;
  public config: PaginationInstance = {
    id: 'advanced',
    itemsPerPage: 1,
    currentPage: 1
  };

  public labels: any = {
    previousLabel: '',
    nextLabel: '',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };
  // Paginador ends
  notas: any[] = [];
  nota: any = {};


  length: number;
  latestIdx: number;
  latestUrl: any;
  index = 0;
  numero: any;
  intervalo:any;

  showIframe = false;

  latestCuadernillo: any;
  cuadernilloURL: string;
  cuadernilloImg: string;

  key: string;

  @ViewChild('popup', { static: false }) popup: ElementRef;
  banners: any;

  constructor(
    public _infosimiService: InfosimiService,
    private router: Router,
    public _bannerService: BannerService,
    private geolocationService: GeolocationService,
    private modalService: ModalServiceService
    ) {

    this.animation = 'fadeIn';
    // Para traer las notas y el id de la nota que se va a navegar
    // this.length = _infosimiService.getNotas().length;
    // this.latestIdx = (this.length - 1);
    // this.latestUrl = _infosimiService.getNotas()[this.latestIdx].url;
    _infosimiService.getNotes().subscribe(resp => {
      this.length = resp.length;
      this.latestIdx = this.length - 1;
      this.latestUrl = resp[this.latestIdx].url;
      this.notas = resp;
    });

    _infosimiService.getLatestCuadernillo().subscribe(resp => {
      this.latestCuadernillo = resp;

      var imagen = this.latestCuadernillo[Math.floor(Math.random() * this.latestCuadernillo.length)];

      this.cuadernilloURL = imagen.URL;
      this.cuadernilloImg = imagen.img;
    });
  }

  ngOnInit() {
    this.getBanner();
    this.isMobile = window.innerWidth < 800;
      window.addEventListener('resize', () => {
        this.isMobile = window.innerWidth < 800;
      });
    // const carousel = document.querySelectorAll('.main-item');
    // this.numero = Math.floor(Math.random() * carousel.length);
    // carousel[this.numero].classList.add('active');
    // To be removed later On
    // if (this.numero === 1) {
    //   this.letreroBanner.nativeElement.style.display = 'none';
    // } else {
    //   this.letreroBanner.nativeElement.style.display = 'block';
    // }

    // this.intervalo = setInterval(() => {
    //   this.changeNumeroAuto(this.index);
    // }, 5000);

  }


  ngAfterViewInit() {
    this.showModal();
  }


  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.key = event.key;
    if (this.key === 'Escape') {
      this.closeModal();
    }
  }

  getBanner() {
    this._bannerService.getBannersHome().subscribe((resp: any) => {
      //console.log(resp);
      this.banners = resp.sort((a, b) => a.orden-b.orden);;
    });
  }

  ngOnDestroy() {
    clearInterval(this.intervalo);
  }

  goTo() {
    this.router.navigate([
      '/producto/CH5769/locion-emulsion-corporalhidratante-pantenol-%2B-urea'
    ]);
  }

  // changeNumeroAuto(numero: any) {
  //   if (numero == 0) {
  //     numero++;
  //   } else if (numero == 4) {
  //     numero = 0;
  //   } else {
  //     numero++;
  //   }

  //   this.index = numero;
  //   const carousel = document.querySelectorAll('.main-item');
  //   const indicators = document.querySelectorAll('.bull');


  //   indicators[numero].classList.add('active');
  //   carousel[numero].classList.add('active');

  //   if (numero == 0) {
  //     indicators[1].classList.remove('active')
  //     indicators[2].classList.remove('active')
  //     // indicators[3].classList.remove('active')
  //     // indicators[4].classList.remove('active')
  //     // indicators[5].classList.remove('active')
  //     carousel[1].classList.remove('active')
  //     carousel[2].classList.remove('active')
  //     // carousel[3].classList.remove('active')
  //     // carousel[4].classList.remove('active')
  //     // carousel[5].classList.remove('active')
  //   } else if (numero == 1) {
  //     indicators[0].classList.remove('active')
  //     indicators[2].classList.remove('active')
  //     // indicators[3].classList.remove('active')
  //     // indicators[4].classList.remove('active')
  //     // indicators[5].classList.remove('active')
  //     carousel[0].classList.remove('active')
  //     carousel[2].classList.remove('active')
  //     // carousel[3].classList.remove('active')
  //     // carousel[4].classList.remove('active')
  //     // carousel[5].classList.remove('active')
  //   } else if (numero == 2) {
  //     indicators[0].classList.remove('active')
  //     indicators[1].classList.remove('active')
  //     // indicators[3].classList.remove('active')
  //     // indicators[4].classList.remove('active')
  //     // indicators[5].classList.remove('active')
  //     carousel[0].classList.remove('active')
  //     carousel[1].classList.remove('active')
  //     // carousel[3].classList.remove('active')
  //     // carousel[4].classList.remove('active')
  //     // carousel[5].classList.remove('active')
  //   } else if (numero == 3) {
  //     indicators[0].classList.remove('active')
  //     indicators[1].classList.remove('active')
  //     indicators[2].classList.remove('active')
  //     indicators[4].classList.remove('active')
  //     carousel[0].classList.remove('active')
  //     carousel[1].classList.remove('active')
  //     carousel[2].classList.remove('active')
  //     carousel[4].classList.remove('active')
  //     carousel[5].classList.remove('active')
  //   } else if (numero == 4) {
  //     indicators[0].classList.remove('active')
  //     indicators[1].classList.remove('active')
  //     indicators[2].classList.remove('active')
  //     indicators[3].classList.remove('active')
  //     indicators[5].classList.remove('active')
  //     carousel[0].classList.remove('active')
  //     carousel[1].classList.remove('active')
  //     carousel[2].classList.remove('active')
  //     carousel[3].classList.remove('active')
  //     carousel[5].classList.remove('active')
  //   } else if (numero == 5) {
  //     indicators[0].classList.remove('active')
  //     indicators[1].classList.remove('active')
  //     indicators[2].classList.remove('active')
  //     indicators[3].classList.remove('active')
  //     indicators[4].classList.remove('active')
  //     carousel[0].classList.remove('active')
  //     carousel[1].classList.remove('active')
  //     carousel[2].classList.remove('active')
  //     carousel[3].classList.remove('active')
  //     carousel[4].classList.remove('active')

  //   }
  // }


  // changeNumero(numero: any) {

  //   this.index = numero;
  //   const carousel = document.querySelectorAll('.main-item');
  //   const indicators = document.querySelectorAll('.bull');

  //   indicators[numero].classList.add('active');
  //   carousel[numero].classList.add('active');

  //   if (numero == 0) {
  //     indicators[1].classList.remove('active')
  //     indicators[2].classList.remove('active')
  //     // indicators[3].classList.remove('active')
  //     // indicators[4].classList.remove('active')
  //     // indicators[5].classList.remove('active')
  //     carousel[1].classList.remove('active')
  //     carousel[2].classList.remove('active')
  //     // carousel[3].classList.remove('active')
  //     // carousel[4].classList.remove('active')
  //     // carousel[5].classList.remove('active')
  //   } else if (numero == 1) {
  //     indicators[0].classList.remove('active')
  //     indicators[2].classList.remove('active')
  //     // indicators[3].classList.remove('active')
  //     // indicators[4].classList.remove('active')
  //     // indicators[5].classList.remove('active')
  //     carousel[0].classList.remove('active')
  //     carousel[2].classList.remove('active')
  //     // carousel[3].classList.remove('active')
  //     // carousel[4].classList.remove('active')
  //     // carousel[5].classList.remove('active')
  //   } else if (numero == 2) {
  //     indicators[0].classList.remove('active')
  //     indicators[1].classList.remove('active')
  //     // indicators[3].classList.remove('active')
  //     // indicators[4].classList.remove('active')
  //     // indicators[5].classList.remove('active')
  //     carousel[0].classList.remove('active')
  //     carousel[1].classList.remove('active')
  //     // carousel[3].classList.remove('active')
  //     // carousel[4].classList.remove('active')
  //     // carousel[5].classList.remove('active')
  //   } else if (numero == 3) {
  //     indicators[0].classList.remove('active')
  //     indicators[1].classList.remove('active')
  //     indicators[2].classList.remove('active')
  //     indicators[4].classList.remove('active')
  //     indicators[5].classList.remove('active')
  //     carousel[0].classList.remove('active')
  //     carousel[1].classList.remove('active')
  //     carousel[2].classList.remove('active')
  //     carousel[4].classList.remove('active')
  //     carousel[5].classList.remove('active')
  //   } else if (numero == 4) {
  //     indicators[0].classList.remove('active')
  //     indicators[1].classList.remove('active')
  //     indicators[2].classList.remove('active')
  //     indicators[3].classList.remove('active')
  //     indicators[5].classList.remove('active')
  //     carousel[0].classList.remove('active')
  //     carousel[1].classList.remove('active')
  //     carousel[2].classList.remove('active')
  //     carousel[3].classList.remove('active')
  //     carousel[5].classList.remove('active')
  //   } else if (numero == 5) {
  //     indicators[0].classList.remove('active')
  //     indicators[1].classList.remove('active')
  //     indicators[2].classList.remove('active')
  //     indicators[3].classList.remove('active')
  //     indicators[4].classList.remove('active')
  //     carousel[0].classList.remove('active')
  //     carousel[1].classList.remove('active')
  //     carousel[2].classList.remove('active')
  //     carousel[3].classList.remove('active')
  //     carousel[4].classList.remove('active')

  //   }
  // }
  /*
  verNota(idx: number, url: string) {
    this.router.navigate(['/infosimi', idx, url]);
  }

  onPageChange(number: number) {
    this.config.currentPage = number;
  }*/

  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;

  closeModal() {
      // stop video
      if (this.videoPlayer && this.videoPlayer.nativeElement) {
          this.videoPlayer.nativeElement.pause();
          this.videoPlayer.nativeElement.currentTime = 0; // Reiniciar el video al principio
      }
      //modal close
      this.popup.nativeElement.style.display = 'none';

      this.modalCoockiesShow();
      this.modalService.setModalState(false);

  }

  showModal() {
    this.popup.nativeElement.style.display = 'flex';
    this.modalService.setModalState(true);

  }

  contentModal() {
    //console.log('modal');
  }
  onVideoEnd() {
    this.closeModal();
    this.modalService.setModalState(true);
  }
  modalCoockiesShow() {
    const coockies = document.getElementById('staticBackdrop');
    const videoplayer = document.getElementById('videoModal');


    if (videoplayer && coockies) {
        if (getComputedStyle(videoplayer).display === 'block') {
            coockies.style.display = 'none';

        } else {
            coockies.style.display = 'block';
            coockies.classList.add('show');  // Añadir la clase para mostrar el modal correctamente
            coockies.setAttribute('aria-modal', 'true');
            coockies.setAttribute('role', 'dialog');
        }
    } else {
        console.error('No se pudo encontrar el modal de video o de cookies');
    }

  }
    closeCoockies(){
      const coockies = document.getElementById('staticBackdrop');
      if(coockies.style.display === 'block'){
        coockies.style.display = 'none'
      }

  }

  //metodo de aceptar o rechazar coockies

  acceptCookies() {
    this.geolocationEnabled = true;
    this.geolocationService.setGeolocationEnabled(this.geolocationEnabled);

  }

  rejectCookies() {
    this.geolocationEnabled = false;
    this.geolocationService.setGeolocationEnabled(this.geolocationEnabled);
  }



}
