import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bioequivalente'
})
export class BioequivalentePipe implements PipeTransform {
  transform(value: string): any {
    if (value.includes('(BE)')) {
      const newValue = value.substr(0, value.length - 4);
      return newValue;
    } else {
      return value;
    }
  }
}
