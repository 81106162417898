import { Component, OnInit } from '@angular/core';
import { InfosimiService } from '../services/infosimi.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.css']
})
export class ContactosComponent implements OnInit {
  constructor(
    private _infosimiService: InfosimiService,
    private router: Router
  ) {}

  ngOnInit() {}

  logout() {
    this._infosimiService.logout();
    this.router.navigate(['/inicio']);
  }
}
